import Loadable from "@loadable/component"
import React from "react"

const LoadableChart = Loadable(
  () => import("../components/Plotly3d")
  //   loading() {
  //     return <div>Loading...</div>
  //   },
)
// const LoadableChart = () => {
//   return <div>loading guy</div>
// }
export default LoadableChart
