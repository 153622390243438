import React from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoadableChart from "../components/LoadableChart"
import { Box, Flex } from "rebass"
import Select from "react-select"
import { Label, Slider } from "@rebass/forms"

const options = [
  { value: "si.com", label: "si.som" },
  { value: "nytimes.com", label: "nytimes.com" },
  { value: "electrek.co", label: "electrek.co" },
  { value: "cleantechnica.com", label: "cleantechnica.com" },
  { value: "arstechnica.com", label: "arstechnica.com" },
  { value: "smoker-cooking.com", label: "smoker-cooking.com" },
  { value: "cbsnews.com", label: "cbsnews.com" },
  { value: "forbes.com", label: "forbes.com" },
]

const Scatter3dPage = (props: { data: PageProps<Queries.DemoData> }) => {
  let parsedData = JSON.parse(props.data.demoData.internal.content)
  const theChart = <LoadableChart traces={parsedData} />
  const theSelect = (
    <Select
      options={options}
      isMulti={true}
      // value={options}
      // onChange={onChangeHandler}
    />
  )

  // const onChangeHandler = (change) => {
  //   console.log(change)
  //   return change
  // }

  return (
    <Layout renderName={false} allowExtraWide={true}>
      <SEO
        title="Scatter3d Demo Page"
        keywords={[`scatter`, `3d`, `embedding`, `bert`, `visualization`, `scatterplot`]}
      />
      <div className="center mw9 pa4">
        {/* <Flex> */}
        <Box>
          <div className="center">{theChart}</div>
        </Box>
        <Box>
          {theSelect}
          <Box>
            <Label htmlFor="rows">Rows to show</Label>
            <Slider id="rows" name="rows" defaultValue={25} />
          </Box>
        </Box>
        {/* </Flex> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    demoData {
      internal {
        content
      }
    }
  }
`

export default Scatter3dPage
